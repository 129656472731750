import Vue from 'vue'
import VueRouter from 'vue-router'
import { getUniqid } from '@/utils/auth'

// 解决 没有token跳转到登录时 路由报错的问题
// https://www.jianshu.com/p/1e462d9b0c03
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
VueRouter.prototype.replace = function replace(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/home.vue'),
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('../views/search.vue'),
  },
  {
    path: '/integrity',
    name: 'integrity',
    component: () => import('../views/integrity.vue'),
  },
  {
    path: '/policy',
    name: 'policy',
    component: () => import('../views/policy.vue'),
  },
  {
    path: '/notice',
    name: 'notice',
    component: () => import('../views/notice.vue'),
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import('../views/detail.vue'),
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/register.vue'),
  },{
    path:'/login',
    name:'login',
    component:() => import('../views/project/login.vue')
  },{
    path:'/course',
    name:'course',
    component:() => import('../views/project/course.vue')
  },{
    path:'/course-detail',
    name:'courseDetail',
    component:() => import('../views/project/courseDetail.vue')
  },{
    path:'/examine',
    name:'examine',
    component:() => import('../views/project/examine.vue')
  },{
    path:'/transcript',
    name:'transcript',
    component:() => import('../views/project/transcript.vue')
  },{
    path:'/analysis',
    name:'analysis',
    component:() => import('../views/project/analysis.vue')
  },{
    path:'/user',
    name:'user',
    component:() => import('../views/project/user.vue')
  }
]

const router = new VueRouter({
  routes
})

// 挂载路由导航守卫,to表示将要访问的路径，from表示从哪里来，next是下一个要做的操作 next('/login')强制跳转login
router.beforeEach((to, from, next) => {
  console.log('to---',to)
  if(to.path=='/course'){
    if(!getUniqid()){
      next({path:'/login'})
    }else{
      next()
    }
  }else{
    next()
  }
  // next()
})

export default router
