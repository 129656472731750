
export function getToken() {
  return window.localStorage.getItem('access_token')
}

export function setToken(token) {
   return window.localStorage.setItem('access_token', token)
}

export function removeToken() {
  return window.localStorage.removeItem('access_token')
}

export function getUniqid(){
  return window.localStorage.getItem('userUniqid')
}
export function setUniqid(id){
  window.localStorage.setItem('userUniqid',id)
}
export function removeUniqid(){
  window.localStorage.removeItem('userUniqid')
}
