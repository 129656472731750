<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  created() {

  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style lang="scss">
  @import "./assets/css/base.css";

  .page-no-data{
    padding: 30px 0;
    width: 100%;
    text-align: center;
    font-size: 12px;
    color: #999;
  }
</style>
